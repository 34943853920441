import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../axiosConfig";
import { Container, Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";

function QuestionForm() {
  const [data, setData] = useState({
    questionText: "",
    correctAnswer: "",
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
    examId: "",
  });
  const [exams, setExams] = useState([]);
  const navigate = useNavigate();
  const { examId, id } = useParams();

  useEffect(() => {
    fetchExams();
    if (id) {
      fetchData();
    } else {
      setData((prevData) => ({ ...prevData, examId }));
    }
  }, [id, examId]);

  const fetchExams = async () => {
    try {
      const response = await axios.get("/exams");
      setExams(response.data);
    } catch (error) {
      console.error("Failed to fetch exams", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`/questions/${id}`);
      setData(response.data);
    } catch (error) {
      console.error("Failed to fetch question", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        await axios.put(`/questions/${id}`, data);
        Swal.fire("Success", "Question updated successfully", "success");
      } else {
        await axios.post(`/questions/exam/${examId}`, data);
        Swal.fire("Success", "Question added successfully", "success");
      }
      navigate(`/dashboard/exams/${data.examId}/questions/list`);
    } catch (error) {
      console.error("Failed to submit question", error);
      Swal.fire("Error", "Failed to submit question", "error");
    }
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Container className="mt-5">
      <h2>{id ? "Edit Question" : "Add New Question"}</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formExamId" className="mt-3">
          <Form.Label>Exam</Form.Label>
          <Form.Control
            as="select"
            name="examId"
            value={data.examId}
            onChange={handleChange}
            required
          >
            <option value="">Select an exam</option>
            {exams.map((exam) => (
              <option key={exam.id} value={exam.id}>
                {exam.title}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formQuestionText" className="mt-3">
          <Form.Label>Question Text</Form.Label>
          <Form.Control
            type="text"
            name="questionText"
            value={data.questionText}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formCorrectAnswer" className="mt-3">
          <Form.Label>Correct Answer</Form.Label>
          <Form.Control
            as="select"
            name="correctAnswer"
            value={data.correctAnswer}
            onChange={handleChange}
            required
          >
            <option value="">Select correct answer</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formOptionA" className="mt-3">
          <Form.Label>Option A</Form.Label>
          <Form.Control
            type="text"
            name="optionA"
            value={data.optionA}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formOptionB" className="mt-3">
          <Form.Label>Option B</Form.Label>
          <Form.Control
            type="text"
            name="optionB"
            value={data.optionB}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formOptionC" className="mt-3">
          <Form.Label>Option C</Form.Label>
          <Form.Control
            type="text"
            name="optionC"
            value={data.optionC}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formOptionD" className="mt-3">
          <Form.Label>Option D</Form.Label>
          <Form.Control
            type="text"
            name="optionD"
            value={data.optionD}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Button type="submit" className="mt-4" variant="primary">
          {id ? "Update" : "Add"}
        </Button>
      </Form>
    </Container>
  );
}

export default QuestionForm;
