import React, { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import axios from "../../axiosConfig";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function AddUser() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("student");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("/users", { username, email, password, role });
      Swal.fire("Success", "User added successfully", "success").then(() => {
        navigate("/dashboard/users/list");
      });
    } catch (error) {
      console.error("Failed to add user", error);
      if (error.response && error.response.status === 409) {
        Swal.fire("Error", error.response.data.message, "error");
      } else {
        Swal.fire("Error", "Failed to add user", "error");
      }
    }
  };

  return (
    <Container>
      <h2>Add User</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="username">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="role">
          <Form.Label>Role</Form.Label>
          <Form.Control
            as="select"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            required
          >
            <option value="student">Student</option>
            <option value="teacher">Teacher</option>
            <option value="admin">Admin</option>
          </Form.Control>
        </Form.Group>
        <Button variant="primary" type="submit">
          Add User
        </Button>
      </Form>
    </Container>
  );
}

export default AddUser;
