import React, { useEffect, useState } from "react";
import axios from "../axiosConfig";
import { Card, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Home() {
  const [subjects, setSubjects] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSubjects();
  }, []);

  const fetchSubjects = async () => {
    try {
      const response = await axios.get("/subjects");
      setSubjects(response.data);
    } catch (error) {
      console.error("Failed to fetch subjects", error);
    }
  };

  const handleSubjectClick = (id) => {
    navigate(`/subjects/${id}`);
  };

  return (
    <Container className="mt-4">
      <h2>Subjects</h2>
      {subjects.map((subject) => (
        <Card
          key={subject.id}
          className="mb-4"
          onClick={() => handleSubjectClick(subject.id)}
          style={{ cursor: "pointer" }}
        >
          <Card.Body>
            <Card.Title>{subject.name}</Card.Title>
            <Card.Text>{subject.description}</Card.Text>
          </Card.Body>
        </Card>
      ))}
    </Container>
  );
}

export default Home;
