import React, { useEffect, useState } from "react";
import axios from "../axiosConfig";
import { Card, Container, Button, Row, Col } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "../styles/ExamDetails.css";

function ExamDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [exam, setExam] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [timeLeft, setTimeLeft] = useState(40); // 60 menit dalam detik

  useEffect(() => {
    fetchExamDetails();
  }, [id]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearInterval(timer);
    } else {
      calculateAndSubmitResult(true); // Pass true when time is up
    }
  }, [timeLeft]);

  const fetchExamDetails = async () => {
    try {
      const response = await axios.get(`/exams/${id}`);
      setExam(response.data);
    } catch (error) {
      console.error("Failed to fetch exam details", error);
    }
  };

  const handleAnswerClick = (option) => {
    setAnswers((prevAnswers) => {
      const newAnswers = { ...prevAnswers };
      if (newAnswers[currentQuestionIndex] === option) {
        delete newAnswers[currentQuestionIndex];
      } else {
        newAnswers[currentQuestionIndex] = option;
      }
      return newAnswers;
    });
  };

  const handleQuestionNavigation = (index) => {
    setCurrentQuestionIndex(index);
  };

  const calculateAndSubmitResult = async (timeUp = false) => {
    if (!exam) return;

    console.log("User answers:", answers);

    const correctAnswers = exam.Questions.reduce((acc, question, index) => {
      const isCorrect = answers[index] === question.correctAnswer.trim();
      console.log(
        `Checking question ${
          index + 1
        }: expected ${question.correctAnswer.trim()}, got ${
          answers[index]
        }, isCorrect: ${isCorrect}`
      );
      return acc + (isCorrect ? 1 : 0);
    }, 0);

    console.log(`Total correct answers: ${correctAnswers}`);

    const score = Math.round((correctAnswers / exam.Questions.length) * 100);
    const userId = localStorage.getItem("userId");

    if (!userId) {
      Swal.fire("Error", "User not logged in. Cannot submit result.", "error");
      navigate("/login");
      return;
    }

    const resultData = {
      userId,
      examId: exam.id,
      score,
      takenAt: new Date().toISOString(),
    };

    console.log("Submitting result data:", resultData);

    try {
      await axios.post("/results", resultData);
      if (timeUp) {
        Swal.fire({
          icon: "warning",
          title: "Time's Up!",
          text: `Your score is ${score}`,
        });
      } else {
        Swal.fire(
          "Success",
          `Exam completed! Your score is ${score}`,
          "success"
        );
      }
      navigate("/dashboard/results/list");
    } catch (error) {
      if (error.response && error.response.status === 403) {
        Swal.fire("Error", "You have already taken this exam.", "error");
      } else {
        console.error("Failed to submit result", error);
        Swal.fire(
          "Error",
          "Failed to submit result. Please try again.",
          "error"
        );
      }
    }
  };

  if (!exam) {
    return <div>Loading...</div>;
  }

  const currentQuestion = exam.Questions[currentQuestionIndex];
  const questionStatus = (index) => {
    if (answers[index] !== undefined) {
      return "btn-success";
    }
    return "btn-secondary";
  };

  const getButtonClass = (option) => {
    return answers[currentQuestionIndex] === option
      ? "btn-success"
      : "btn-light";
  };

  return (
    <Container className="mt-4">
      <Row>
        <Col lg={8} md={12}>
          {currentQuestion ? (
            <>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>
                    Question number {currentQuestionIndex + 1}
                  </Card.Title>
                  <Card.Text>{currentQuestion.questionText}</Card.Text>
                </Card.Body>
              </Card>
              <Row>
                <Col xs={12} sm={6} className="mb-3">
                  <Button
                    className={`w-100 ${getButtonClass("A")}`}
                    onClick={() => handleAnswerClick("A")}
                  >
                    {currentQuestion.optionA}
                  </Button>
                </Col>
                <Col xs={12} sm={6} className="mb-3">
                  <Button
                    className={`w-100 ${getButtonClass("B")}`}
                    onClick={() => handleAnswerClick("B")}
                  >
                    {currentQuestion.optionB}
                  </Button>
                </Col>
                <Col xs={12} sm={6} className="mb-3">
                  <Button
                    className={`w-100 ${getButtonClass("C")}`}
                    onClick={() => handleAnswerClick("C")}
                  >
                    {currentQuestion.optionC}
                  </Button>
                </Col>
                <Col xs={12} sm={6} className="mb-3">
                  <Button
                    className={`w-100 ${getButtonClass("D")}`}
                    onClick={() => handleAnswerClick("D")}
                  >
                    {currentQuestion.optionD}
                  </Button>
                </Col>
              </Row>
              {currentQuestionIndex === exam.Questions.length - 1 ? (
                <Button onClick={() => calculateAndSubmitResult(false)}>
                  Submit Exam
                </Button>
              ) : (
                <Button
                  onClick={() =>
                    handleQuestionNavigation(currentQuestionIndex + 1)
                  }
                >
                  Next
                </Button>
              )}
            </>
          ) : (
            <div>No questions available for this exam.</div>
          )}
        </Col>
        <Col lg={4} md={12}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>
                Countdown: {Math.floor(timeLeft / 60)}:{timeLeft % 60}
              </Card.Title>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              {exam.Questions.map((_, index) => (
                <Button
                  key={index}
                  className={`btn ${questionStatus(index)} mb-2`}
                  onClick={() => handleQuestionNavigation(index)}
                >
                  {index + 1}
                </Button>
              ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ExamDetails;
