import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import axios from "../../axiosConfig";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function ListMaterials() {
  const [materials, setMaterials] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchMaterials();
  }, []);

  const fetchMaterials = async () => {
    try {
      const response = await axios.get("/materials");
      setMaterials(response.data);
    } catch (error) {
      console.error("Failed to fetch materials", error);
    }
  };

  const handleEdit = (id) => {
    navigate(`/dashboard/materials/edit/${id}`);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/materials/${id}`);
          fetchMaterials();
          Swal.fire("Deleted!", "The material has been deleted.", "success");
        } catch (error) {
          console.error("Failed to delete material", error);
          Swal.fire("Error", "Failed to delete material", "error");
        }
      }
    });
  };

  return (
    <div>
      <h2>Materials List</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Id</th>
            <th>Title</th>
            <th>Content</th>
            <th>Subject Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {materials.map((material) => (
            <tr key={material.id}>
              <td>{material.id}</td>
              <td>{material.title}</td>
              <td>{material.content}</td>
              <td>{material.Subject.name}</td>
              <td>
                <Button
                  variant="warning"
                  onClick={() => handleEdit(material.id)}
                >
                  Edit
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDelete(material.id)}
                  className="ml-2"
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default ListMaterials;
