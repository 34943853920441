import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import axios from "../../axiosConfig";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function ListResults() {
  const [results, setResults] = useState([]);
  const navigate = useNavigate();
  const role = localStorage.getItem("role");

  useEffect(() => {
    fetchResults();
  }, []);

  const fetchResults = async () => {
    try {
      const response = await axios.get("/results");
      setResults(response.data);
    } catch (error) {
      console.error("Failed to fetch results", error);
    }
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await axios.delete(`/results/${id}`);
        fetchResults();
        Swal.fire("Deleted!", "Your result has been deleted.", "success");
      } catch (error) {
        console.error("Failed to delete result", error);
        Swal.fire("Error", "Failed to delete result", "error");
      }
    }
  };

  return (
    <div>
      <h2>Results List</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Id</th>
            <th>Username</th>
            <th>Exam Title</th>
            <th>Score</th>
            <th>Taken At</th>
            {role === "teacher" || role === "admin" ? <th>Action</th> : null}
          </tr>
        </thead>
        <tbody>
          {results.map((result) => (
            <tr key={result.id}>
              <td>{result.id}</td>
              <td>{result.User ? result.User.username : "N/A"}</td>
              <td>{result.Exam ? result.Exam.title : "N/A"}</td>
              <td>{result.score}</td>
              <td>{new Date(result.takenAt).toLocaleString()}</td>
              {role === "teacher" || role === "admin" ? (
                <td>
                  <Button
                    variant="danger"
                    onClick={() => handleDelete(result.id)}
                  >
                    Delete
                  </Button>
                </td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default ListResults;
