import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import axios from "../../axiosConfig";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function ListSubjects() {
  const [subjects, setSubjects] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSubjects();
  }, []);

  const fetchSubjects = async () => {
    try {
      const response = await axios.get("/subjects");
      setSubjects(response.data);
    } catch (error) {
      console.error("Failed to fetch subjects", error);
    }
  };

  const handleEdit = (id) => {
    navigate(`/dashboard/subjects/edit/${id}`);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/subjects/${id}`);
          fetchSubjects();
          Swal.fire("Deleted!", "The subject has been deleted.", "success");
        } catch (error) {
          console.error("Failed to delete subject", error);
          Swal.fire("Error", "Failed to delete subject", "error");
        }
      }
    });
  };

  return (
    <div>
      <h2>Subjects List</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Description</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {subjects.map((subject) => (
            <tr key={subject.id}>
              <td>{subject.id}</td>
              <td>{subject.name}</td>
              <td>{subject.description}</td>
              <td>
                <Button
                  variant="warning"
                  onClick={() => handleEdit(subject.id)}
                >
                  Edit
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDelete(subject.id)}
                  className="ml-2"
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default ListSubjects;
