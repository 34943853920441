import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";

function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <Container fluid className="p-0">
      <Row className="vh-100">
        <Col xs={sidebarOpen ? 3 : 1} className="sidebar-container p-0">
          <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
        </Col>
        <Col xs={sidebarOpen ? 9 : 11} className="main-content p-4">
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
