import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";

function Login() {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${config.apiBaseUrl}/users/login`, {
        login,
        password,
      });
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userId", response.data.user.id);
      localStorage.setItem("role", response.data.user.role);
      const role = response.data.user.role;
      if (role === "admin" || role === "teacher") {
        navigate("/dashboard");
        Swal.fire("Login Successful", "You are an admin or teacher", "success");
      } else {
        navigate("/");
        Swal.fire("Login Successful", "Welcome", "success");
      }
    } catch (error) {
      console.error("Login failed", error.response.data.message);
      Swal.fire("Login Failed", "Invalid credentials", "error");
    }
  };

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <Row className="w-100">
        <Col xs={12} md={6} lg={4} className="mx-auto">
          <h2 className="text-center mb-4">QuizForYou</h2>
          <Form onSubmit={handleSubmit} className="login-form">
            <Form.Group controlId="formLogin">
              <Form.Control
                type="text"
                placeholder="Email or Username"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formPassword" className="mt-3">
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>
            <Button type="submit" className="w-100 mt-4" variant="primary">
              Login
            </Button>
            <div className="d-flex justify-content-between mt-3">
              <a href="/forgot-password" className="text-decoration-none">
                Forgot Password?
              </a>
              <a href="/register" className="text-decoration-none">
                Sign Up
              </a>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
