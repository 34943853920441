import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import axios from "../../axiosConfig";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function ListExams() {
  const [exams, setExams] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchExams();
  }, []);

  const fetchExams = async () => {
    try {
      const response = await axios.get("/exams");
      setExams(response.data);
    } catch (error) {
      console.error("Failed to fetch exams", error);
    }
  };

  const handleEdit = (id) => {
    navigate(`/dashboard/exams/edit/${id}`);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/exams/${id}`);
          fetchExams();
          Swal.fire("Deleted!", "The exam has been deleted.", "success");
        } catch (error) {
          console.error("Failed to delete exam", error);
          Swal.fire("Error", "Failed to delete exam", "error");
        }
      }
    });
  };

  const handleListQuestions = (examId) => {
    navigate(`/dashboard/exams/${examId}/questions/list`);
  };

  const handleAddQuestion = (examId) => {
    navigate(`/dashboard/exams/${examId}/questions/new`);
  };

  return (
    <div>
      <h2>Exams List</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Id</th>
            <th>Title</th>
            <th>Subject Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {exams.map((exam) => (
            <tr key={exam.id}>
              <td>{exam.id}</td>
              <td>{exam.title}</td>
              <td>{exam.Subject.name}</td>
              <td>
                <Button variant="warning" onClick={() => handleEdit(exam.id)}>
                  Edit
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDelete(exam.id)}
                  className="ml-2"
                >
                  Delete
                </Button>
                <Button
                  variant="primary"
                  onClick={() => handleListQuestions(exam.id)}
                  className="ml-2"
                >
                  List Questions
                </Button>
                <Button
                  variant="success"
                  onClick={() => handleAddQuestion(exam.id)}
                  className="ml-2"
                >
                  Add Question
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default ListExams;
