import React, { useEffect, useState } from "react";
import axios from "../axiosConfig";
import { Card, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";

function MaterialDetails() {
  const { id } = useParams();
  const [material, setMaterial] = useState(null);

  useEffect(() => {
    fetchMaterialDetails();
  }, [id]);

  const fetchMaterialDetails = async () => {
    try {
      const response = await axios.get(`/materials/${id}`);
      setMaterial(response.data);
    } catch (error) {
      console.error("Failed to fetch material details", error);
    }
  };

  if (!material) {
    return <div>Loading...</div>;
  }

  return (
    <Container className="mt-4">
      <h2>{material.title}</h2>
      <p>{material.content}</p>
    </Container>
  );
}

export default MaterialDetails;
