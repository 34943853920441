import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../axiosConfig";
import { Container, Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";

function SubjectForm() {
  const [data, setData] = useState({ name: "", description: "" });
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`/subjects/${id}`);
      setData(response.data);
    } catch (error) {
      console.error("Failed to fetch subject", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        await axios.put(`/subjects/${id}`, data);
        Swal.fire("Success", "Subject updated successfully", "success");
      } else {
        await axios.post(`/subjects`, data);
        Swal.fire("Success", "Subject added successfully", "success");
      }
      navigate("/dashboard/subjects/list");
    } catch (error) {
      console.error("Failed to submit subject", error);
      Swal.fire("Error", "Failed to submit subject", "error");
    }
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Container className="mt-5">
      <h2>{id ? "Edit Subject" : "Add New Subject"}</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName" className="mt-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={data.name}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formDescription" className="mt-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            name="description"
            value={data.description}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Button type="submit" className="mt-4" variant="primary">
          {id ? "Update" : "Add"}
        </Button>
      </Form>
    </Container>
  );
}

export default SubjectForm;
