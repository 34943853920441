import React, { useState, useEffect } from "react";
import { Form, Button, Container } from "react-bootstrap";
import axios from "../../axiosConfig";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function EditProfile() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [image, setImage] = useState(null);
  const [changePassword, setChangePassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await axios.get(`/users/${userId}`);
      const user = response.data;
      setUsername(user.username);
      setEmail(user.email);
      setRole(user.role);
      setImage(user.image);
    } catch (error) {
      console.error("Failed to fetch user profile", error);
    }
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = localStorage.getItem("userId");
    const formData = new FormData();
    formData.append("username", username);
    formData.append("email", email);
    formData.append("role", role);
    if (image) {
      formData.append("image", image);
    }

    if (changePassword) {
      if (newPassword !== confirmNewPassword) {
        Swal.fire("Error", "New passwords do not match", "error");
        return;
      }
      formData.append("oldPassword", oldPassword);
      formData.append("newPassword", newPassword);
    }

    try {
      await axios.put(`/users/profile/${userId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (changePassword) {
        Swal.fire({
          title: "Password Changed",
          text: "Password changed successfully. Please log in again.",
          icon: "success",
        }).then(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("userId");
          localStorage.removeItem("role");
          navigate("/login");
        });
      } else {
        Swal.fire("Success", "Profile updated successfully", "success").then(
          () => {
            // Triggering event to refresh sidebar
            const event = new CustomEvent("profileUpdated");
            window.dispatchEvent(event);
            fetchUserProfile();
          }
        );
      }
    } catch (error) {
      console.error("Failed to update profile", error);
      Swal.fire("Error", "Failed to update profile", "error");
    }
  };

  return (
    <Container>
      <h2>Edit Profile</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="username">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="image">
          <Form.Label>Profile Picture</Form.Label>
          <Form.Control type="file" onChange={handleImageChange} />
        </Form.Group>
        <Form.Group controlId="changePassword">
          <Form.Check
            type="checkbox"
            label="Change Password"
            checked={changePassword}
            onChange={(e) => setChangePassword(e.target.checked)}
          />
        </Form.Group>
        {changePassword && (
          <>
            <Form.Group controlId="oldPassword">
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                type="password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required={changePassword}
              />
            </Form.Group>
            <Form.Group controlId="newPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required={changePassword}
              />
            </Form.Group>
            <Form.Group controlId="confirmNewPassword">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                type="password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                required={changePassword}
              />
            </Form.Group>
          </>
        )}
        <Button variant="primary" type="submit">
          Update Profile
        </Button>
      </Form>
    </Container>
  );
}

export default EditProfile;
