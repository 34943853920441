import React, { useEffect, useState } from "react";
import axios from "../axiosConfig";
import { Card, Container } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";

function SubjectDetails() {
  const { id } = useParams();
  const [subject, setSubject] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSubjectDetails();
  }, [id]);

  const fetchSubjectDetails = async () => {
    try {
      const response = await axios.get(`/subjects/${id}`);
      setSubject(response.data);
    } catch (error) {
      console.error("Failed to fetch subject details", error);
    }
  };

  const handleMaterialClick = (materialId) => {
    navigate(`/materials/${materialId}`);
  };

  const handleExamClick = (examId) => {
    navigate(`/exams/${examId}`);
  };

  if (!subject) {
    return <div>Loading...</div>;
  }

  return (
    <Container className="mt-4">
      <h2>{subject.name}</h2>
      <p>{subject.description}</p>
      <h4>Materials</h4>
      {subject.Materials.map((material) => (
        <Card
          key={material.id}
          className="mb-2"
          onClick={() => handleMaterialClick(material.id)}
          style={{ cursor: "pointer" }}
        >
          <Card.Body>
            <Card.Title>{material.title}</Card.Title>
          </Card.Body>
        </Card>
      ))}
      <h4>Exams</h4>
      {subject.Exams.map((exam) => (
        <Card
          key={exam.id}
          className="mb-2"
          onClick={() => handleExamClick(exam.id)}
          style={{ cursor: "pointer" }}
        >
          <Card.Body>
            <Card.Title>{exam.title}</Card.Title>
          </Card.Body>
        </Card>
      ))}
    </Container>
  );
}

export default SubjectDetails;
