import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../axiosConfig";
import { Container, Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";

function ExamForm() {
  const [data, setData] = useState({ title: "", subjectId: "" });
  const [subjects, setSubjects] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchSubjects();
    if (id) {
      fetchData();
    }
  }, [id]);

  const fetchSubjects = async () => {
    try {
      const response = await axios.get("/subjects");
      setSubjects(response.data);
    } catch (error) {
      console.error("Failed to fetch subjects", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`/exams/${id}`);
      setData(response.data);
    } catch (error) {
      console.error("Failed to fetch exam", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        await axios.put(`/exams/${id}`, data);
        Swal.fire("Success", "Exam updated successfully", "success");
      } else {
        await axios.post(`/exams`, data);
        Swal.fire("Success", "Exam added successfully", "success");
      }
      navigate("/dashboard/exams/list");
    } catch (error) {
      console.error("Failed to submit exam", error);
      Swal.fire("Error", "Failed to submit exam", "error");
    }
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Container className="mt-5">
      <h2>{id ? "Edit Exam" : "Add New Exam"}</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formSubjectId" className="mt-3">
          <Form.Label>Subject</Form.Label>
          <Form.Control
            as="select"
            name="subjectId"
            value={data.subjectId}
            onChange={handleChange}
            required
          >
            <option value="">Select a subject</option>
            {subjects.map((subject) => (
              <option key={subject.id} value={subject.id}>
                {subject.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formTitle" className="mt-3">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            name="title"
            value={data.title}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Button type="submit" className="mt-4" variant="primary">
          {id ? "Update" : "Add"}
        </Button>
      </Form>
    </Container>
  );
}

export default ExamForm;
