import React, { useState, useEffect } from "react";
import { Form, Button, Container } from "react-bootstrap";
import axios from "../../axiosConfig";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function EditUser() {
  const { id } = useParams();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [image, setImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`/users/${id}`);
      const user = response.data;
      setUsername(user.username);
      setEmail(user.email);
      setRole(user.role);
    } catch (error) {
      console.error("Failed to fetch user data", error);
    }
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("username", username);
    formData.append("email", email);
    formData.append("role", role);
    if (image) {
      formData.append("image", image);
    }

    try {
      await axios.put(`/users/admin/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      Swal.fire("Success", "User updated successfully", "success").then(() => {
        navigate("/dashboard/users/list");
      });
    } catch (error) {
      console.error("Failed to update user", error);
      Swal.fire("Error", "Failed to update user", "error");
    }
  };

  return (
    <Container>
      <h2>Edit User</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="username">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="role">
          <Form.Label>Role</Form.Label>
          <Form.Control
            type="text"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="image">
          <Form.Label>Profile Picture</Form.Label>
          <Form.Control type="file" onChange={handleImageChange} />
        </Form.Group>
        <Button variant="primary" type="submit">
          Update User
        </Button>
      </Form>
    </Container>
  );
}

export default EditUser;
