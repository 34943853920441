import React, { useEffect, useState } from "react";
import { Nav, Button, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "../axiosConfig";
import config from "../config";
import "../styles/Sidebar.css";

function Sidebar({ sidebarOpen, toggleSidebar }) {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [openMenu, setOpenMenu] = useState("");
  const role = localStorage.getItem("role");

  useEffect(() => {
    fetchUserData();

    // Event listener untuk memperbarui data pengguna jika ada perubahan profil
    const handleProfileUpdated = () => fetchUserData();
    window.addEventListener("profileUpdated", handleProfileUpdated);

    return () => {
      window.removeEventListener("profileUpdated", handleProfileUpdated);
    };
  }, []); // Hanya dijalankan sekali saat komponen dimuat

  const fetchUserData = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await axios.get(`/users/${userId}`);
      setUser(response.data);
    } catch (error) {
      console.error("Failed to fetch user data", error);
      // Tangani error jika gagal mengambil data pengguna
    }
  };

  const handleMenuClick = (menu) => {
    setOpenMenu(openMenu === menu ? "" : menu);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("role");
    navigate("/login");
  };

  return (
    <div
      className={`sidebar d-flex flex-column p-3 bg-light ${
        sidebarOpen ? "" : "collapsed"
      }`}
      style={{ height: "100vh" }}
    >
      <div className="sidebar-header">
        <button className="toggle-btn" onClick={toggleSidebar}>
          ☰ {/* Ikon untuk toggle sidebar */}
        </button>
      </div>

      {/* Konten sidebar hanya ditampilkan saat tidak collapsed */}
      {!sidebarOpen || (
        <div className="sidebar-content">
          <div className="profile text-center">
            {user.image && (
              <img
                src={`${config.imageBaseUrl}/${user.image}`}
                alt="Profile"
                className="img-fluid rounded-circle"
                style={{ width: "100%", height: "100%" }}
              />
            )}
            <h6 className="mt-2">{user.username}</h6>
            <p className="mt-1">{user.role}</p>
          </div>
          <Nav className="flex-column">
            {role === "admin" && (
              <>
                {/* Menu Subjects */}
                <NavDropdown title="Subjects" id="subjects-dropdown">
                  <NavDropdown.Item
                    onClick={() => navigate("/dashboard/subjects/list")}
                  >
                    List Subjects
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => navigate("/dashboard/subjects/new")}
                  >
                    Add Subjects
                  </NavDropdown.Item>
                </NavDropdown>

                {/* Menu Exams */}
                <NavDropdown title="Exams" id="exams-dropdown">
                  <NavDropdown.Item
                    onClick={() => navigate("/dashboard/exams/list")}
                  >
                    List Exams
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => navigate("/dashboard/exams/new")}
                  >
                    Add Exams
                  </NavDropdown.Item>
                </NavDropdown>

                {/* Menu Materials */}
                <NavDropdown title="Materials" id="materials-dropdown">
                  <NavDropdown.Item
                    onClick={() => navigate("/dashboard/materials/list")}
                  >
                    List Materials
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => navigate("/dashboard/materials/new")}
                  >
                    Add Materials
                  </NavDropdown.Item>
                </NavDropdown>

                {/* Menu Users */}
                <NavDropdown title="Users" id="users-dropdown">
                  <NavDropdown.Item
                    onClick={() => navigate("/dashboard/users/list")}
                  >
                    List Users
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => navigate("/dashboard/users/new")}
                  >
                    Add User
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            )}

            {role === "teacher" && (
              <>
                {/* Menu Subjects */}
                <NavDropdown title="Subjects" id="subjects-dropdown">
                  <NavDropdown.Item
                    onClick={() => navigate("/dashboard/subjects/list")}
                  >
                    List Subjects
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => navigate("/dashboard/subjects/new")}
                  >
                    Add Subjects
                  </NavDropdown.Item>
                </NavDropdown>

                {/* Menu Exams */}
                <NavDropdown title="Exams" id="exams-dropdown">
                  <NavDropdown.Item
                    onClick={() => navigate("/dashboard/exams/list")}
                  >
                    List Exams
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => navigate("/dashboard/exams/new")}
                  >
                    Add Exams
                  </NavDropdown.Item>
                </NavDropdown>

                {/* Menu Materials */}
                <NavDropdown title="Materials" id="materials-dropdown">
                  <NavDropdown.Item
                    onClick={() => navigate("/dashboard/materials/list")}
                  >
                    List Materials
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => navigate("/dashboard/materials/new")}
                  >
                    Add Materials
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            )}

            {role === "student" && (
              <>
                {/* Menu Results */}
                <Nav.Item>
                  <Nav.Link onClick={() => navigate("/dashboard/results/list")}>
                    List Results
                  </Nav.Link>
                </Nav.Item>
              </>
            )}

            {/* Navigasi umum untuk semua peran */}
            <Nav.Item>
              <Nav.Link onClick={() => navigate("/dashboard/edit-profile")}>
                Edit Profile
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <div className="logout">
            <Button variant="danger" onClick={handleLogout}>
              Logout
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Sidebar;
