import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import axios from "../../axiosConfig";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

function ListQuestions() {
  const [questions, setQuestions] = useState([]);
  const [exam, setExam] = useState(null);
  const { examId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchExam();
    fetchQuestions();
  }, [examId]);

  const fetchExam = async () => {
    try {
      const response = await axios.get(`/exams/${examId}`);
      setExam(response.data);
    } catch (error) {
      console.error("Failed to fetch exam", error);
    }
  };

  const fetchQuestions = async () => {
    try {
      const response = await axios.get(`/questions/exam/${examId}`);
      setQuestions(response.data);
    } catch (error) {
      console.error("Failed to fetch questions", error);
    }
  };

  const handleEdit = (id) => {
    navigate(`/dashboard/questions/edit/${id}`);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/questions/${id}`);
          fetchQuestions();
          Swal.fire("Deleted!", "The question has been deleted.", "success");
        } catch (error) {
          console.error("Failed to delete question", error);
          Swal.fire("Error", "Failed to delete question", "error");
        }
      }
    });
  };

  const handleAddQuestion = () => {
    navigate(`/dashboard/exams/${examId}/questions/new`);
  };

  return (
    <div>
      <h2>
        Questions List for{" "}
        {exam ? `${exam.title} - ${exam.Subject.name}` : "Loading..."}
      </h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Id</th>
            <th>Question Text</th>
            <th>Correct Answer</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {questions.map((question) => (
            <tr key={question.id}>
              <td>{question.id}</td>
              <td>{question.questionText}</td>
              <td>{question.correctAnswer}</td>
              <td>
                <Button
                  variant="warning"
                  onClick={() => handleEdit(question.id)}
                >
                  Edit
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDelete(question.id)}
                  className="ml-2"
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button variant="success" onClick={handleAddQuestion} className="mt-3">
        Add Question
      </Button>
    </div>
  );
}

export default ListQuestions;
